import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import './styles/_main.sass'
import ScrollToTop from './components/ScrollToTop'

import Send from './pages/Send'
import Signin from './pages/Signin'

const App = () => {
  const [user, setUser] = useState(false)
  const [loadingUser, setLoadingUser] = useState(true)

  useEffect(async () => {
    const u = await axios.get('/api/v0/me')
    console.log(u.data)
    if (u.status === 200) setUser(u.data)
    setLoadingUser(false)
  }, [])

  if (loadingUser) return <div>Loading...</div>

  return (
            <Router>
              <ScrollToTop />
              <div style={{ minHeight: '100vh', boxSizing: 'border-box', padding: '24px' }}>
                <Switch>
                  <Route path="/send">{user ? <Send /> : <Redirect to="/signin" />}</Route>
                  <Route exact path="/signin">{user ? <Redirect to="/send" /> : <Signin />}</Route>
                  <Route path="*"><Redirect to={user ? '/send' : '/signin'} /></Route>
                </Switch>
              </div>
            </Router>
  )
}

export default App

import axios from 'axios'
import { Switch, Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

const Home = () => {
    const handleLogout = async () => {
        await axios.delete('/auth/logout')
        window.location.reload()
    }

    return (
        <div>
            Before you can send funds, regulation requires us to first verify your identity account information.
            <button onClick={() => handleLogout()}>Sign Out</button>
        </div>
    )
}

const Send = () => {
    return (
        <Switch>
            <Route exact path='/send/dashboard'><Home /></Route>
            <Route path='*'><Redirect to="/send/dashboard" /></Route>
        </Switch>
    )
}

export default Send

import { useState } from 'react'
import logo from 'assets/zapremit-logo-wide-transparent.png'
import axios from 'axios'

const Signin = () => {

    const [email, setEmail] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [codeSent, setCodeSent] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [code, setCode] = useState('')

    const validateEmail = () => email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    const handleSubmit = async e => {
        e.preventDefault()
        if (submitting) return
        if (!codeSent) { 
            // handle sending code to email
            if (!validateEmail()) return setErrorMessage('Please input a valid email address.')
            setErrorMessage(null)
            setSubmitting(true)
            axios.get(`/auth/code?email=${email}`)
                .then(() => {
                    setSubmitting(false)
                    setCodeSent(true)
                })
                .catch(() => {
                    setSubmitting(false)
                    setErrorMessage('Unable to connect to server.')
                })
        } else {
            // handle check if code is valid & complete auth.
            setErrorMessage(null)
            setSubmitting(true)
            axios.get(`/auth/verify-async?email=${email}&code=${code}`)
                .then(() => {
                    setSubmitting(false)
                    window.location.reload()
                })
                .catch(() => {
                    setSubmitting(false)
                    setErrorMessage('Invalid Code.')
                })
        }
    }
    
    return (
        <>
            <form onSubmit={e => handleSubmit(e)}>
                <div style={{display:'flex', flexDirection: 'column', alignItems: 'center', margin: '48px 10px'}}>
                    <img src={logo} style={{width: '240px'}}/>
                    <div style={{height: '40px'}}></div>
                    {!codeSent ?
                    <>
                        <label className='label' htmlFor="email">Email Authentication</label>
                        <input 
                            className='input'
                            name="email" 
                            placeholder="example@gmail.com"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </>
                    :
                    <>
                        <label className='label' htmlFor="code">Enter Code Sent to {email}</label>
                        <input 
                            className='input'
                            name="code" 
                            placeholder="ABCD1234"
                            value={code}
                            onChange={e => setCode(e.target.value.toUpperCase())}
                        />
                    </>
                    }
                    {errorMessage ? <div style={{marginTop: '10px', color: '#FE7133'}}>{errorMessage}</div> : null}
                    {submitting
                        ? <button className='button' style={{pointerEvents: 'none', opacity: '0.3'}}>Submitting</button>
                        : <button className='button'>Proceed</button>
                    }
                    <div style={{fontSize: '13px', maxWidth: '500px', textAlign: 'center', marginTop: '20px', opacity: '.6'}}>
                        ZapRemit will send a 1-time access code to your designated email account. If an email does not arrive in your inbox within a minute, it might have landed in your spam folder. If this is your first time, you will be prompted to complete your account.
                    </div>
                </div>
            </form>
        </>
    )
}

export default Signin
